<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">アクセスログ</label>
      </CCardHeader>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <CButton color="info" size="sm" class="m-1" style="height:35px;" @click="csvDownload">CSVダウンロード</CButton>
          </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <!--            <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/>-->
          <!--            <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort">-->
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="roleNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <!-- cell -->
          <template slot-scope="{ row,index }" slot="roleName"><div class="text-left">{{ row.roleName }}</div></template>
          <template slot-scope="{ row,index }" slot="email"><div class="text-left">{{ row.email }}</div></template>
          <template slot-scope="{ row,index }" slot="description"><div class="text-left">{{ row.description }}</div></template>
          <template slot-scope="{ row,index }" slot="clientIp"><div class="text-left">{{ row.clientIp }}</div></template>
          <template slot-scope="{ row,index }" slot="agencyName"><div class="text-left">{{ row.agencyName }}</div></template>
          <template slot-scope="{ row,index }" slot="agencyBranchName"><div class="text-left">{{ row.agencyBranchName }}</div></template>
          <template slot-scope="{ row,index }" slot="hospitalName"><div class="text-left">{{ row.hospitalName }}</div></template>
          <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{ row.created }}</div></template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
        name: 'AccessLog',
        components: {
          MyBeeHeader, MyBeeSearchBar
        },
        mixins: [BeeMix],
        data() {
          return{
            columns: [
            //{field: 'no',label: 'No',  sortable: false, width: "70px"},
            {key: 'name', title: 'アクセス者名',headSlot:"nameHead",align:'center'},
            {key: 'roleName',slot: 'roleName', title: '権限',headSlot:"roleNameHead",align:'center'},
            {key: 'email',slot: 'email', title: 'email',align:'center'},
            {key: 'description',slot: 'description', title: 'アクセス内容',align:'center'},
            {key: 'clientIp',slot: 'clientIp', title: 'IP Address',align:'center'},
            {key: 'agencyName',slot: 'agencyName', title: '販売店名',align:'center'},
            {key: 'agencyBranchName',slot: 'agencyBranchName', title: '販売事業所名',align:'center'},
            {key: 'hospitalName',slot: 'hospitalName', title: '医療機関名',align:'center'},
            {key: 'created',slot: 'created', title: 'アクセス日時',align:'center'},
          ],pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "created", sort: "desc"}]}),
            searchBarPlaceHolder:"アクセス者名、emailで検索"
        }
        },
        methods: {
          formInit: function () {
            this.formData = {selectedTarget: [], title: null, content: null, fromDate: null, toDate: null};
          },
          goodPaging(isPageChange) {
            if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
            this.isLoading = true;
            this.axios.post('/api/accessLog/paging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
              this.totalRecords = response.data.result.totalElements;
              this.rows = response.data.result.content;
              //ページ件数
              this.pages = response.data.result.totalPages;
              this.topLeft();
            }).catch(error => console.log(error)
            ).finally(() => {
              this.isLoading = false;
            });
          }, csvDownload() {
            this.isLoading = true;
            this.axios.post('/api/accessLog/csvDownload',
                this.pagingParams
                , {headers: {'Content-Type': 'application/json'}, responseType: 'blob'}).then(
                response => {
                  let url = window.URL.createObjectURL(new Blob([response.data]));
                  let link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', decodeURI(response.headers.filename));
                  document.body.appendChild(link);
                  link.click();

                }).catch(error => console.log(error)
            ).finally(() => this.isLoading = false);
          }
        }
    }
</script>
<style scoped>
    .notice_form * {
        font-size: 1.1rem;
    }

    pre {
        white-space: pre-wrap; /* Since CSS 2.1 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        /*white-space: -pre-wrap;      !* Opera 4-6 *!*/
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
    }
    .vgt-global-search__actions .badge:hover {
        cursor: pointer;
    }
</style>
